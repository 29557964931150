import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // Import your CSS file for styling

const SaleBillTrash = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [blno, setblno] = useState(0)

    const handleOpenModal = (e) => { setblno(e); setIsModalOpen(true); };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    var billgst;
    
    const today = new Date();
    const [refreshData, setRefreshData] = useState(false)
    const api = "/bill";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [Room, setRoom] = useState([])

    const [data, setData] = useState()
    const Closeform = () => {
        window.location.assign("/SaleBillTrash")
    }

    const [logdetail, setlogdetail] = useState({
        onform: "Trash",
        operation: "",
        newdesc: "",
        olddesc: "",
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const [viewbldate, setBldate] = useState({
        blfromdate: today.toISOString().split('T')[0],
        bltodate:today.toISOString().split('T')[0]
    })
    const {blfromdate,bltodate} = viewbldate;

    const bldatechange = (e)=>{
        console.log(e.target.value)
        const {name, value} = e.target;
        setBldate({...viewbldate, [name]:value})
        setRefreshData(true)
    }


    const getrent = (e) => {
        axios.get(`${hostlink}/room/${e}`)
            .then((Response) => {

                document.getElementById("extrabadchrge").value = Response.data.exrent
                document.getElementById("roomrent").value = Response.data.rent
                var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent }
                setSavedata(ld);
            })
            .catch((Response) => { "data Not Found" })
    }
    const getroombyid = (e) => {

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/billview/1/${blfromdate}/${bltodate}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/billview/1/${blfromdate}/${bltodate}/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const saveBill = async (e) => {
        await axios.put(`${hostlink}/bill`, billdata)
            .then(() => {
                toast("Save Sucessfull")
                setTimeout(() => {
                    window.location.assign("/SaleBillTrash")
                }, 1000);
            })
            .catch(() => {
                console.log("Data Not Save")
            })
    }


    const [savedata, setSavedata] = useState({
        id: "",
        indate: "",
        mobno: "",
        gname: "",
        add1: "",
        add2: "",
        documentno: "",
        documentpath: "",
        email: "",
        gstno: "",
        cname: "",
        rtype: "",
        rcode: "",
        rent: "",
        exbed: "",
        exbedch: "",
        mg: "",
        fg: "",
        cg: "",
        stday: "",
        discount: "",
        advance: "",
        paidby: "",
        remarks: ""
    })

    const { mobno, gname, add1, add2, documentno, documentpath, indate, email, gstno, cname, rcode, rtype, rent, exbed, exbedch, mg, fg, cg, stday, discount, advance, paidby, remarks } = savedata;
    const [billdata, setBilldata] = useState({
        id: "",
        checkindate: "",
        checkoutdate: today.toISOString().split('T')[0],
        bldate: today.toISOString().split('T')[0],
        roomcode: "",
        guestroomname: "",
        roomrent: 0,
        extrabed: 0,
        extrabedch: 0,
        discountperc: 0,
        adddiscountamt: 0,
        additionalcharge: 0,
        advanceamount: 0,
        blpaidby: "",
        guestmob: "",
        guestname: "",
        guestadd1: "",
        guestadd2: "",
        guestdocno: "",
        guestemail: "",
        guestgstno: "",
        guestcompanyname: "",
        blremarks: "",
        bltotaldays: "",
        bltotal: "",
        blroomrent: "",
        discountpercamt: "",
        blgstperc: "",
        guestroomname: "",
        blgstamt: "",
        blamt: "",
        netpayamt: "",
        checkinid: ""
    })
    const { checkinid, checkindate, checkoutdate, roomcode, roomrent, extrabed, extrabedch, discountperc, adddiscountamt, additionalcharge, advanceamount, blpaidby, guestmob, guestname, guestadd1, guestadd2, guestdocno, guestemail, guestgstno, guestcompanyname, blremarks, bltotaldays, bltotal, blroomrent, discountpercamt, blgstperc, blgstamt, blamt, netpayamt } = billdata;


    const billtotal = (e) => {
        var checkindateString = document.getElementById("checkindate").value;
        var checkoutdateString = document.getElementById("checkoutdate").value;
        var checkindate = new Date(checkindateString);
        var checkoutdate = new Date(checkoutdateString);


        if (!isNaN(checkindate) && !isNaN(checkoutdate)) {
            var differenceInMilliseconds = Math.abs(checkoutdate - checkindate);
            var differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            var value = differenceInDays;
            value = (value === 0) ? 1 : value;
            var currRent = Number(document.getElementById("roomrent").value) + (Number(document.getElementById("extrabed").value) * Number(document.getElementById("extrabedch").value))
            if (Number(usercontrol.GST_1) > Number(currRent)) {
                billgst = "0"; console.log(billgst);
            } else if (Number(usercontrol.GST_2) > Number(currRent)) {
                billgst = "5"; console.log(billgst);
            } else if (Number(usercontrol.GST_3) > Number(currRent)) {
                billgst = "12"; console.log(billgst);
            } else if (Number(usercontrol.GST_4) > Number(currRent)) {
                billgst = "18"; console.log(billgst);
            } else if (Number(usercontrol.GST_4) <= Number(currRent)) {
                billgst = "28"; console.log(billgst);
            }


            var ld = {
                ...billdata, bltotaldays: value,
                bltotal: (value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100),
                bldate: document.getElementById("checkoutdate").value,
                blroomrent: (Number(roomrent) + ((extrabed * extrabedch))),
                discountpercamt: (value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100,
                blgstperc: billgst, gueststaydays: value,
                blgstamt: ((value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100)) * billgst / 100,
                blamt: (((Number((value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100))) + Number(((value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100)) * billgst / 100)) + Number(additionalcharge)) - Number(adddiscountamt),
                netpayamt: ((((Number((value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100))) + Number(((value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100)) * billgst / 100)) + Number(additionalcharge)) - Number(adddiscountamt)) - Number(advanceamount)
            }
            setBilldata(ld);

        } else {
            console.log("Invalid date format");
        }
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const onChangevaluebill = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setBilldata({ ...billdata, [name]: sanitizedValue });

    }

    const updateStatus = async (e, Roomname) => {
        console.log(`${hostlink}/bill/0/${e}`)
        try {
            await axios.post(`${hostlink}/bill/0/${e}`)
            axios.get(`${hostlink}/bill/${e}`)
            .then((responce)=>{
                   var ld = {...logdetail,
                   newdesc: `Bill No : ${responce.data.id}, Bl Date : ${responce.data.bldate}, Bill Amt : ${responce.data.blamt}`,
                   operation: "Restore-Bill"}
                   setlogdetail(ld);saveLog(ld);
            })
            
            toast.success("Restore Sucessfull")
                setRefreshData(true)
                .then((Response) => {
                })
                .catch((res) => { console.log("Data Not find") })

        } catch (error) { }
    }
    const columns = [
        { name: 'Sr No', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "5%" },
        { name: 'Bl No', sortable: true, selector: row => row.id, },
        { name: 'Bl Date', sortable: true, selector: row => row.bldate, },
        { name: 'Time', sortable: true, selector: row => row.entrytime, },
        { name: 'Room', sortable: true, selector: row => row.guestroomname, },
        { name: 'Bill Amt', sortable: true, selector: row => row.blamt, },
        { name: 'Guest Name', sortable: true, selector: row => row.guestname, },
        { name: 'Guest Mob', sortable: true, selector: row => row.guestmob, },
        { name: 'Guest Add', sortable: true, selector: row => row.guestadd1, },
        {
            name: 'Actions', "width": "18%", cell: (row) => (<div>
                <Link to={"/SaleBillTrash"} onClick={(e) => { updateStatus(`${row.id}`) }} className='btn btn-sm btn-outline-dark'>Restore</Link>&nbsp;
                <Link onClick={(e) => { handleOpenModal(row.id) }} className='btn btn-sm btn-danger'>Delete</Link>&nbsp;
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this Bill No : {blno} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>
            </div>), allowOverflow: true, button: true,
        },
    ];
    const deleteData = async (e) => {
        try {
              axios.delete(`${hostlink}${api}/${e}`)
           
                axios.get(`${hostlink}/bill/${e}`)
                .then((responce)=>{
                       var ld = {...logdetail,
                       newdesc: `Bill No : ${responce.data.id}, Bl Date : ${responce.data.bldate}, Bill Amt : ${responce.data.blamt}`,
                       operation: "Delete-Bill"}
                       setlogdetail(ld);saveLog(ld);
                })
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                 window.location.assign("/SaleBillTrash")
                setRefreshData(true)
            }, 1000);
        } catch (error) { }
    }
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        axios.get(`${hostlink}/billview/1/${blfromdate}/${bltodate}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }, [refreshData])
    return (
        <div className='p-1'>
            <div id='frm_checkoutdetail' className='container shadow rounded mt-1 ' style={{ display: "none" }}>
                <h5 className='font-monospace text-primary mt-1'> <i class="fa-solid fa-trash-can"></i> <span id='pagemodeout' > Trash Details</span></h5>
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Detail</span></h5>
                <div className='col-md-12'>
                    <div className='row'>
                        <div class="col-md-9">
                            <div className='container'>
                                <div className='row'>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Check In Date</label>
                                        <input type="date" class="form-control" onChange={(e) => { onChangevaluebill(e) }} onBlur={(e) => { billtotal() }} name='checkindate' value={checkindate} id="checkindate" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Check OUT Date</label>
                                        <input type="date" class="form-control" onChange={(e) => { onChangevaluebill(e) }} onBlur={(e) => { billtotal() }} name='checkoutdate' value={checkoutdate} id="checkoutdate" /> <br />
                                    </div>
                                    <div class="col-md-2">
                                        <label for="inputState" class="form-label">Room</label>
                                        <select onChange={(e) => { onChangevaluebill(e) }} onBlur={(e) => { getrent(e.target.value) }} name='roomcode' value={roomcode} id='checkoutroomname' class="form-select">
                                            <option selected key={0} value={0}>Choose...</option>
                                            {Room.map((x) => {
                                                return (
                                                    <option key={x.id} value={x.id}> {x.rname} </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Rent</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='roomrent' value={roomrent} id='roomrent' onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Extra Bed</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='extrabed' value={extrabed} id='extrabed' onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Bed Charge</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='extrabedch' value={extrabedch} id='extrabedch' onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Discount %</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='discountperc' value={discountperc} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Flat Discount</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='adddiscountamt' value={adddiscountamt} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Additional Charge</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='additionalcharge' value={additionalcharge} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Advance</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='advanceamount' value={advanceamount} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Paid By</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='blpaidby' value={blpaidby} class="form-control" /> <br />
                                    </div>
                                    {/* Guest Detail */}
                                    <h5 className='font-monospace text-primary'> <i class="fa-regular fa-address-card"></i> <span id='pagemodeaaa' > Guest Details On Bill</span></h5>
                                    <div class="col-md-2">
                                        <label className='font-monospace' class="form-label">Mob No</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestmob' value={guestmob} class="form-control" /> <br />
                                    </div>
                                    <div class="col-md-4">
                                        <label className='font-monospace' class="form-label">Guest Name</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestname' value={guestname} class="form-control" /> <br />
                                    </div>
                                    <div class="col-md-3">
                                        <label className='font-monospace' class="form-label">Add 1</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestadd1' value={guestadd1} class="form-control" /> <br />
                                    </div>
                                    <div class="col-md-3">
                                        <label className='font-monospace' class="form-label">Add 2</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestadd2' value={guestadd2} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Document No</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestdocno' value={guestdocno} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-4">
                                        <label className='font-monospace' class="form-label">Select Document</label>
                                        <input type="file" class="form-control" id="documentpath" />
                                    </div>
                                    <div className="col-md-3">
                                        <label className='font-monospace' class="form-label">Email</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestemail' value={guestemail} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-3">
                                        <label className='font-monospace' class="form-label">GST No</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestgstno' value={guestgstno} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-3">
                                        <label className='font-monospace' class="form-label">Company Name</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestcompanyname' value={guestcompanyname} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-4">
                                        <label className='font-monospace' class="form-label">Remarks</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='blremarks' value={blremarks} class="form-control" /> <br />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Summary  Detail */}
                        <div class="col-md-3 " >
                            <div class="col-md-12 shadow rounded " style={{ borderRadius: "50px 50px 50px " }}>
                                <br />
                                <h5 className='font-monospace text-primary text-center'>  <i class="fa-solid fa-right-from-bracket"></i>  <span id='pagemodesummary' > Summary</span></h5>
                                <div className='container border-1'>
                                    <div className='row'>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Total Days :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' name='bltotaldays' value={bltotaldays} class="form-label">{bltotaldays}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Room Rent :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' name='blroomrent' value={blroomrent} class="form-label">{parseFloat(blroomrent).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>
                                        <div className='col-8   text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Discount :</label>
                                        </div>
                                        <div className='col-4  text-end'>
                                            <label className='font-monospace ' name='discountpercamt' value={discountpercamt} class="form-label">{parseFloat(discountpercamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>

                                        <div className='col-8  bg-info-subtle text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Total :</label>
                                        </div>
                                        <div className='col-4  bg-info-subtle text-end'>
                                            <label className='font-monospace ' name='bltotal' value={bltotal} class="form-label">{parseFloat(bltotal).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">GST {blgstperc} % : </label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' class="form-label">{parseFloat(blgstamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Additional Charge :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' class="form-label">{additionalcharge}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Additional Discount :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' class="form-label">{adddiscountamt}</label>
                                        </div>
                                        <div className='col-8 bg-warning-subtle text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Bill Amount :</label>
                                        </div>
                                        <div className='col-4 bg-warning-subtle text-end'>
                                            <label className='font-monospace' class="form-label">{parseFloat(blamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Advance :</label>
                                        </div>
                                        <div className='col-4  text-end'>
                                            <label className='font-monospace' class="form-label">{parseFloat(advanceamount).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label>
                                        </div>
                                        <div className='col-8 bg-warning-subtle text-end'>
                                            <strong> <label className='font-monospace text-end' class="form-label">Net Payable Amount :</label></strong>
                                        </div>
                                        <div className='col-4 bg-warning-subtle text-end'>
                                            <strong> <label className='font-monospace bg-danger ' class="form-label">{parseFloat(netpayamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label></strong>
                                        </div>

                                        <div class="col-md-12  text-end">
                                            <br />
                                            <button id='cmdsave' onClick={() => { saveBill() }} class="btn btn-primary">Save Bill</button>  &nbsp;
                                            <button onClick={Closeform} type="submit" class="btn btn-outline-dark">Cancel</button>
                                            <br />
                                            <br />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>



            </div>
            {/* /// Check In  */}
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode' > Check-OUT [ New ]</span></h5>
                <div className='row'>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Mob No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='mobno' value={mobno} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Guest Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gname' value={gname} class="form-control" id="inputname1" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 1</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add1' value={add1} class="form-control" id="inputname11" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 2</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add2' value={add2} class="form-control" id="inputname111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Document No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='documentno' value={documentno} class="form-control" id="inputname1111" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Select Document</label>
                        <input type="file" class="form-control" id="documentpath" />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Email</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="inputname11111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">GST No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="inputname111111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Company Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cname' value={cname} class="form-control" id="inputname1111111" /> <br />
                    </div>


                    {/*- Stay Details -------------------------------------*/}
                    <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Detail</span></h5>

                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Check In Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='indate' value={indate} id="1inputname" /> <br />
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room Type</label>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e); getroombyid(e.target.value); }} name='rtype' value={rtype} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {RoomTYpe.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rtname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room</label>
                        <select id="roomcode" onChange={(e) => { onChangevalue(e) }} onBlur={(e) => { getrent(e.target.value) }} name='rcode' value={rcode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Room.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Rent</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='rent' value={rent} class="form-control" id="roomrent" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Extra Bed</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbed' value={exbed} class="form-control" id="111inputname" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Bed Charge</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbedch' value={exbedch} class="form-control" id="extrabadchrge" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Male</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mg' value={mg} class="form-control" id="11111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Female</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='fg' value={fg} class="form-control" id="111111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Child</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='cg' value={cg} class="form-control" id="2inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Stay Days</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='stday' value={stday} class="form-control" id="22inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Dis %</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='discount' value={discount} class="form-control" id="222inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Advance</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='advance' value={advance} class="form-control" id="2222inputname" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Paid By</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='paidby' value={paidby} class="form-control" id="22222inputname" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Remarks</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='remarks' value={remarks} class="form-control" id="12inputname" /> <br />
                    </div>

                </div>
                <div class="col-md-12 text-end">

                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-trash-can"></i> Trash Details</h5>
                    </div>
                    <div className='col-md-3 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                            
                    </div>

                    <div className='col-md-1 text-end align-self-end'><label  htmlFor=""  className='font-monospace form-label '>From : </label></div>
                    <div className='col-md-2 text-end'><input type="date" id='blfromdate' onChange={(e)=>{bldatechange(e)}} name='blfromdate' value={blfromdate} className='form-control' /></div>

                    <div className='col-md-1 text-end align-self-end'><label  htmlFor=""  className='font-monospace form-label '>To : </label></div>
                    <div className='col-md-2 text-end'><input type="date" id='bltodate'   onChange={(e)=>{bldatechange(e)}} name='bltodate' value={bltodate} className='form-control' /></div>

                    <div style={{ height: "80vh" }} className='col-12 '>

                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default SaleBillTrash
