const user_code = 1
const user_computer = "Admin"
const GST_1 = 100 //5
const GST_2 = 200 //12
const GST_3 = 300 //18
const GST_4 = 400 //28


export default {
    user_code,
    user_computer,
    GST_1,GST_2,GST_3,GST_4,
};
