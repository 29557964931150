import axios, { Axios } from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol' 
import Modal from 'react-modal';
import './MessageBox.css'; // 

const RoomMaster = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid:"",
        deletemsg:""
    })
    const {deleteid, deletemsg} = messagedata;


    const handleOpenModal = (e,flname) => { 
        setMessagedata({...messagedata, deleteid:e,deletemsg:flname})
        setIsModalOpen(true); };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const api = "/room";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const [refreshData, setRefreshData] = useState(false)

    const [logdetail, setlogdetail] = useState({
        onform: "Room Master",
        operation: "",
        newdesc: "",
        olddesc: "",
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const [Floor, setFloor] = useState([])
    const [RoomTYpe, setRoomTYpe] = useState([])
    const Closeform = () => {
        window.location.assign("/RoomMaster")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            setRefreshData(true)
        } else {
            axios.get(`${hostlink}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const SaveData = async (e) => {
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        try {
            if (document.getElementById("pagemode").innerHTML == ' Room [ NEW ]') {
                await axios.post(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        console.log(savedata)
                        var ld = { ...logdetail, newdesc: "Name : " + `${savedata.rname}` + ", Rent : " + `${savedata.rent}`+ ", Ex Bed : " + `${savedata.exrent}`, operation: "New"}
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/RoomMaster")
                        }, 1000);
                    })
                    .catch((Response) => {
                        console.log(savedata)
                        toast.error("Name already exists.")
                        
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                await axios.put(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: "Name : " + `${savedata.rname}` + ", Rent : " + `${savedata.rent}`+ ", Ex Bed : " + `${savedata.exrent}`, operation: "Edit"}
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/RoomMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error(`${savedata.rname}` +" Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        rname: "",
        fcode: "",
        rtcode: "",
        exrent:"",
        rent: ""
    })

    const { rname, fcode, rtcode, rent, exrent } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }

    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(ld)
        console.log("Log Saved...")
    }


    const cmdEdit = (e) => {
        setPagemode(1);
        document.getElementById("pagemode").innerHTML = " Room [ EDIT ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("inputname").focus();
        document.getElementById("cmdnew").disabled = true
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
                var ld = { ...logdetail, olddesc: "Name : " + `${Response.data.rname}` + ", Rent : " + `${Response.data.rent}`+ ", Ex Bed : " + `${Response.data.exrent}`, operation: "Edit"}
                setlogdetail(ld);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        setlogdetail({ ...logdetail, operation: "New", olddesc: "" });
        document.getElementById("pagemode").innerHTML = " Room [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
    }
    const deleteData = async (e,name) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
                .then((Response) => {
                    toast.success("Delete Sucessfull")
                    var ld = { ...logdetail, newdesc: "Name : " + deletemsg , operation: "Delete"}
                    setlogdetail(ld);
                    saveLog(ld)
                    setInterval(() => {
                        handleCloseModal();
                        setRefreshData(true)
                    }, 1000);
                })
        } catch (error) { }
    }
    const columns = [
        { name: 'ID', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "8%" },
        { name: 'Room Name', sortable: true, selector: row => row.rname, },
        { name: 'Rent', sortable: true, selector: row => row.rent, },
        {
            name: 'Actions', "width": "35%", cell: (row) => (<div>
                <Link to={"/RoomMaster"} onClick={(e) => { cmdEdit(`${row.id}`) }} className='btn btn-sm btn-outline-dark'>Edit</Link> &nbsp;
                {/* <Link onClick={() => deleteData(`${row.id}`,`${row.rname}`)} className='btn btn-sm btn-danger'>Delete</Link> */}
                <Link onClick={(e) => { handleOpenModal(row.id, row.rname) }} className='btn btn-sm btn-danger'>Delete</Link>&nbsp;
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br/> Room : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>

            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        axios.get(`${hostlink}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
                setRefreshData(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/floor`)
            .then((Response) => {
                setFloor(Response.data)
            })
            .catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
            })
            .catch((Response) => { "data Not Found" })    
    }, [refreshData])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-person-shelter"></i> <span id='pagemode' > Room [ New ]</span></h5>
                <div className='row p-2 m-1'>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label"> Room Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='rname' value={rname} class="form-control" id="inputname" />
                    </div>

                    <div class="col-md-4">
                        <label for="inputState" class="form-label">Room Type</label>
                        <select id="inputrtcode" onChange={(e) => { onChangevalue(e) }} name='rtcode'  value={rtcode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {RoomTYpe.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rtname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="inputState" class="form-label">Floor Name</label>
                        <select id="inputfcode" onChange={(e) => { onChangevalue(e) }} name='fcode' value={fcode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Floor.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.flname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <p></p>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label"> Rent @/Day </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='rent' value={rent} class="form-control" id="inputrent" />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label"> Extra Bed @/Day </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exrent' value={exrent} class="form-control" id="inputexrent" />
                    </div>
                </div>
                <div class="col-md-12 text-end">
                    <br />
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-person-shelter"></i> Room Master</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-person-shelter"></i> &nbsp; New Room</button>
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default RoomMaster
