import React from 'react'
import css from "./navbar.module.css"
const Navbar = () => {
    return (
        <div id={css.menubar}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
                <div className="container-fluid bg-body-secondary shadow-lg">
                    <a className="navbar-brand" href="/"><i class="fa-solid fa-house"></i></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                            <li className="nav-item dropdown active">
                                <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Master
                                </a>
                                <ul className="dropdown-menu">
                                    {/* <li><a className="dropdown-item" href="/ItemMaster"><i class="fa-solid fa-sitemap" style={{width:"20px"}}></i> Item Master</a></li>
                                    // <li><a className="dropdown-item" href="/ItemUnit_View"><i class="fa-solid fa-cart-flatbed-suitcase" style={{width:"20px"}}></i > Item Unit Master</a></li>
                                    <li><a className="dropdown-item" href="/ItemGroupMaster"><i class="fa-solid fa-layer-group" style={{width:"20px"}}></i> Item Group Master</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="/DishHeadMaster"><i class="fa-solid fa-building-flag" style={{width:"20px"}}></i> Dish Head Master </a></li>
                                    <li><a className="dropdown-item" href="/DishTypeMaster"><i class="fa-solid fa-bag-shopping" style={{width:"20px"}}></i> Dish Type Master </a></li>
                                    <li><a className="dropdown-item" href="/ItemCompanyMaster"><i class="fa-regular fa-building" style={{width:"20px"}}></i> Item Company Master </a></li>
                                    <li><hr className="dropdown-divider" /></li> */}
                                    <li><a className="dropdown-item" href="/FloorMaster"><i class="fa-solid fa-tree" style={{ width: "20px" }}></i> Floor Master</a></li>
                                    <li><a className="dropdown-item" href="/RoomTypeMaster"><i class="fa-solid fa-filter" style={{ width: "20px" }}></i> Room Type</a></li>
                                    <li><a className="dropdown-item" href="/RoomMaster"><i class="fa-solid fa-person-shelter" style={{ width: "20px" }}></i> Room Master</a></li>
                                    {/* <li><a className="dropdown-item" href="/TableMaster"><i class="fa-solid fa-table-cells-large" style={{width:"20px"}}></i> Table Master</a></li> */}
                                    {/* <li><hr className="dropdown-divider" /></li> */}
                                    {/* <li><a className="dropdown-item" href="/"><i class="fa-regular fa-address-card" style={{width:"20px"}}></i> Account Master</a></li>
                                    <li><a className="dropdown-item" href="/"><i class="fa-regular fa-address-book" style={{width:"20px"}}></i> H.D. Customer</a></li> */}
                                    {/* <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="/KitchenMaster"><i class="fa-solid fa-kitchen-set" style={{width:"20px"}}></i> Kitchen Master</a></li> */}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href='/Reservation' className="nav-link active"> <i class="fa-solid fa-book"></i> Reservation </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link active" href='/CheckIn'><i class="fa-solid fa-door-open"></i> Check in</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='/CheckOut'><i class="fa-solid fa-right-from-bracket"></i> Check out</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link active" href='/SaleBill'><i class="fa-solid fa-database"></i> Bill View</a>
                            </li>


                            {/* <li className="nav-item">
                                <a href='/' className="nav-link active"><i class="fa-solid fa-warehouse"></i> Inventory</a>
                            </li> */}
                            <li className="nav-item">
                                <a href='/' className="nav-link active"><i class="fa-solid fa-tent-arrow-left-right"></i> Transaction</a>
                            </li>
                            <li className="nav-item dropdown active">
                                <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa-solid fa-flag"></i> Report
                                </a>



                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <a href='/Logbook' className="nav-link active"> &nbsp; <i class="fa-solid fa-flag"></i> Logbook</a>
                                        <a href='/SaleBillTrash' className="nav-link active"> &nbsp; <i class="fa-solid fa-trash-can"></i> Trash </a>
                                        <a href='/SaleBillCancel' className="nav-link active"> &nbsp; <i class="fa-solid fa-ban"></i> Cancelled Bill </a>
                                    </li>
                                </ul>


                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='/Settings'><i class="fa-solid fa-gear"></i> Configuration </a>
                            </li>

                            {/* <li className="nav-item">
                                <a href='/' className="nav-link active"><i class="fa-solid fa-comment-sms"></i> Sms</a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a href='/' className="nav-link active"><i class="fa-solid fa-envelopes-bulk"></i> Email</a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a href='/' className="nav-link active"><i class="fa-solid fa-lock"></i> Day Close</a>
                            </li> */}
                        </ul>

                        <form className="d-flex" role="search">
                            <a style={{ textDecoration: "none" }} href="/">USER : ADMIN</a>
                        </form>
                    </div>
                </div>
            </nav>



        </div>
    )
}

export default Navbar
