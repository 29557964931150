import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import hostlink from '../Hostlink/hostlink'
const Logbook = () => {
  const [data, setData] = useState()
  const api = "/logbook";
  const columns = [
    { name: 'Sr No', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "5%" },
    { name: 'Date', sortable: true, selector: row => row.logdate, "width": "8%"},
    { name: 'Time', sortable: true, selector: row => row.logtime, "width": "7%"},
    { name: 'Form', sortable: true, selector: row => row.onform, "width": "8%"},
    { name: 'Action', sortable: true, selector: row => row.operation, "width": "8%"},
    { name: 'New Description', sortable: true, selector: row => row.newdesc, wrap: true },
    { name: 'Old Description', sortable: true, selector: row => row.olddesc, wrap: true},
    {name: 'Actions', "width": "0%", cell: (row) => (<div>
        </div>), allowOverflow: true, button: true,
    },
];

  useEffect(() => {
    axios.get(`${hostlink}${api}`)
      .then((Response) => {
        console.log(`${hostlink}${api}`)
        console.log("Data Fatched")
        setData(Response.data)
      })
      .catch((Response) => {
        console.log("Data Not Fatched")
      })
  }, [])
  return (

    <div className="container p-1" >
                    <div className='col-md-4 p-2'>
                        <h5 className='font-monospace'><i class="fa-solid fa-chart-simple"></i> Logbook</h5>
                    </div>
      <div className='col-12'>
        
        <div style={{ height: "80vh" }} className='col-12 '>
          <table class="table table-striped">
            <tbody>
              <DataTable
                columns= {columns}
                data={data}
                pagination
                fixedHeader
                fixedHeaderScrollHeight='530px'
              />
            </tbody>
          </table>
        </div>
        </div>
    </div>


  )
}

export default Logbook