import axios from 'axios'

import React, { useEffect, useState } from 'react'

const Test = () => {
    const [data, setdata] = useState([])
    useEffect(()=>{
        axios.get("https://jsonplaceholder.typicode.com/users")
        .then((response)=>{
            setdata(response.data)
        })
    })
  return (
    <div>
       
        {/* {data.map((user) => (
                <h1 key={user.id}>{user.name}</h1>
            ))} */}

        {data.map((user)=>(
            <h1>{user.name}</h1>
        ))}

    </div>
  )
}

export default Test