import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ItemMaster = () => {
    const api = "/item";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const [DishType, setDishType] = useState([])
    const [Itg, setItg] = useState([])
    const [Itc, setItc] = useState([])
    const [Kitchen, setKitchen] = useState([])

    const Closeform = () => {
        window.location.assign("/ItemMaster")
    }

    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const SaveData = async (e) => {
        const inputValue = document.getElementById("itname").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        document.getElementById("cmdsave").disabled = true
        try {
            if (pagemode === '0') {
                await axios.post(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            window.location.assign("/ItemMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                await axios.put(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            window.location.assign("/ItemMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        itname: "",
        barcode: "",
        hsncode: "",
        takerate: "",
        restrate: "",
        dlvrate: "",
        onlinerate: "",
        purcrate: "",
        mrp: "",
        opstock: "",
        itg: "",
        itc: "",
        dtcode: "",
        kcode: "",
        gst: "",
        cess: ""
    })
    const { itname, barcode, hsncode, takerate , restrate, dlvrate, onlinerate, purcrate, mrp, opstock, itg, itc,dtcode,kcode,gst,cess} = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setPagemode(1);
        document.getElementById("pagemode").innerHTML = " Item [ EDIT ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("inputname").focus();
        document.getElementById("cmdnew").disabled = true
        console.log(`${hostlink}${api}/${e}`)
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        document.getElementById("pagemode").innerHTML = " Item [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
    }
    const deleteData = async (e) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                window.location.assign("/ItemMaster")
            }, 1000);
        } catch (error) { }
    }
    const columns = [
        { name: 'ID', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "8%" },
        { name: 'Item Name', sortable: true, selector: row => row.rname, },
        {
            name: 'Actions', "width": "35%", cell: (row) => (<div>
                <Link to={"/ItemMaster"} onClick={(e) => { cmdEdit(`${row.id}`) }} className='btn btn-sm btn-outline-dark'>Edit</Link> &nbsp;
                <Link onClick={() => deleteData(`${row.id}`)} className='btn btn-sm btn-danger'>Delete</Link>
            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        axios.get(`${hostlink}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/Dishtype`).then((Response) => { setDishType(Response.data) }).catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/ItemGroup`).then((Response) => { setItg(Response.data) }).catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/kitchen`).then((Response) => { setKitchen(Response.data) }).catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/Itcomp`).then((Response) => { setItc(Response.data) }).catch((Response) => { "data Not Found" })
    }, [])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-sitemap"></i> <span id='pagemode' > Item [ New ]</span></h5>
                {/* <form class="row g-3"> */}
                <div className='row p-2 m-1'>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Item Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='itname' value={itname} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Barcode</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='barcode' value={barcode} class="form-control" id="inputname" />
                    </div>

                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> HSN Code</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='hsncode' value={hsncode} class="form-control" id="inputname" />
                    </div>
                    <p></p>
                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Item Group Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='itg' value={itg} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Itg.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.groupname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Item Company Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='itc' value={itc} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Itc.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.itcname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Kitchen Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='kcode' value={kcode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Kitchen.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.kitchenname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Dish Type Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='dtcode' value={dtcode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {DishType.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.dtname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <p></p>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Dine-in Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='restrate' value={restrate} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Takeaway Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='takerate' value={takerate} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Delivery Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='dlvrate' value={dlvrate} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Online Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='onlinerate' value={onlinerate} class="form-control" id="inputname" />
                    </div>
                    <p></p>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Mrp </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mrp' value={mrp} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Purchase Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='purcrate' value={purcrate} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Gst % </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='gst' value={gst} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Cess % </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='cess' value={cess} class="form-control" id="inputname" />
                    </div>
                    <p></p>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Opening Stock </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='opstock' value={opstock} class="form-control" id="inputname" />
                    </div>


                </div>
                <div class="col-md-12 text-end">
                    <br />
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-sitemap"></i> Item Master</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-sitemap"></i> &nbsp; New Item</button>
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default ItemMaster
