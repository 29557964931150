import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol' 
import Modal from 'react-modal';
import './MessageBox.css'; // 

const RoomTypeMaster = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid:"",
        deletemsg:""
    })
    const {deleteid, deletemsg} = messagedata;
    const handleOpenModal = (e,flname) => { 
        setMessagedata({...messagedata, deleteid:e,deletemsg:flname})
        setIsModalOpen(true); };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };


    const [refreshData, setRefreshData] = useState(false)
    const api = "/rtmas";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const Closeform = () => {
        setRefreshData(true)
    }
    const [logdetail, setlogdetail] = useState({
        onform: "RoomType",
        operation: "",
        newdesc: "",
        olddesc: "",
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }
    const SaveData = async (e) => {
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        document.getElementById("cmdsave").disabled = true
        try {
            if (document.getElementById("pagemode").innerHTML == ' Room Type [ NEW ]') {
              
                await axios.post(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: document.getElementById("inputname").value.trim(), operation: "New"}
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            setRefreshData(true)
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                await axios.put(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = { ...logdetail, newdesc: "Name : " + document.getElementById("inputname").value.trim()}
                            setlogdetail(ld);
                            saveLog(ld)    
                            window.location.assign("/RoomTypeMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        rtname: ""
    })
    const { rtname } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setPagemode(1);
        document.getElementById("pagemode").innerHTML = " Room Type [ EDIT ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("inputname").focus();
        document.getElementById("cmdnew").disabled = true
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
                var ld = { ...logdetail, olddesc: "Name : " + Response.data.rtname, operation: "Edit"}
                setlogdetail(ld);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        document.getElementById("pagemode").innerHTML = " Room Type [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
    }
    const deleteData = async (e,rtname) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete"}
            setlogdetail(ld);
            saveLog(ld)    
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/RoomTypeMaster")
            }, 1000);
        } catch (error) { }
    }
    const columns = [
        { name: 'Sr No', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "8%" },
        { name: 'Room Type', sortable: true, selector: row => row.rtname, },
        {
            name: 'Actions', "width": "35%", cell: (row) => (<div>
                <Link to={"/RoomTypeMaster"} onClick={(e) => {cmdEdit(`${row.id}`)}} className='btn btn-sm btn-outline-dark'>Edit</Link> &nbsp;
                <Link onClick={(e) => { handleOpenModal(row.id, row.rtname) }} className='btn btn-sm btn-danger'>Delete</Link>&nbsp;
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br/> Room Type : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>
            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        document.getElementById("cmdnew").disabled = false
        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        axios.get(`${hostlink}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [refreshData])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-filter"></i> <span id='pagemode' > Room Type [ New ]</span></h5>
                {/* <form class="row g-3"> */}
                <div class="col-md-7">
                    <label className='font-monospace' class="form-label"> Room Type</label>
                    <input type="text" onChange={(e) => { onChangevalue(e) }} name='rtname' value={rtname} class="form-control" id="inputname" /> <br />
                </div>
                <div class="col-md-7 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />

                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-filter"></i> Room Type</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; Create New </button>
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default RoomTypeMaster
