import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ItemGroupMaster = () => {
    const api = "/ItemGroup";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const Closeform = () => {
        window.location.assign("/ItemGroupMaster")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => {})
        } else {
            
            axios.get(`${hostlink}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => {})
        }
    }
    const SaveData = async (e) => {
        const inputValue =  document.getElementById("inputname").value.trim();
        if (inputValue === "") 
        {
            toast.error("Please Enter Name")
            return false
        }
        document.getElementById("cmdsave").disabled = true
        try {
            if (pagemode === '0') {
                await axios.post(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            window.location.assign("/ItemGroupMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                await axios.put(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            window.location.assign("/ItemGroupMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        groupname: ""
    })
    const { groupname } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setPagemode(1);
        document.getElementById("pagemode").innerHTML = " Group [ EDIT ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("inputname").focus();
        document.getElementById("cmdnew").disabled = true
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        document.getElementById("pagemode").innerHTML = " Group [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
    }
    const deleteData = async (e) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                window.location.assign("/ItemGroupMaster")
            }, 1000);
        } catch (error) { }
    }
    const columns = [
        { name: 'ID', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "8%" },
        { name: 'Group Name', sortable: true, selector: row => row.groupname, },
        {
            name: 'Actions', "width": "35%", cell: (row) => (<div>
                <Link to={"/ItemGroupMaster"} onClick={(e) => { cmdEdit(`${row.id}`) }} className='btn btn-sm btn-outline-dark'>Edit</Link> &nbsp;
                <Link onClick={() => deleteData(`${row.id}`)} className='btn btn-sm btn-danger'>Delete</Link>
            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        axios.get(`${hostlink}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-layer-group"></i> <span id='pagemode' > Group [ New ]</span></h5>
                <div class="col-md-7">
                    <label className='font-monospace' class="form-label"> Group Name</label>
                    <input type="text" onChange={(e) => { onChangevalue(e) }} name='groupname' value={groupname} class="form-control" id="inputname" /> <br />
                </div>
                <div class="col-md-7 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />

                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-layer-group"></i> Item  Group Master</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width:"100%"}} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-layer-group"></i> &nbsp; New Group</button>
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default ItemGroupMaster
