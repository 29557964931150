import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 

const Checkin = () => {
    const today = new Date();
    const [logroom, setLogroom] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "",
        updatercode:""
    })
    const { deleteid, deletemsg, updatercode } = messagedata;


    const handleOpenModal = (e, flname, uprcode) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname, updatercode:uprcode })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [refreshData, setRefreshData] = useState(false)
    const api = "/checkin";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const Closeform = () => {
        window.location.assign("/Checkin")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "CheckIN",
        operation: "",
        newdesc: "",
        olddesc: "",
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const getrent = (e) => {
        axios.get(`${hostlink}/room/${e}`)
            .then((Response) => {

                document.getElementById("extrabadchrge").value = Response.data.exrent
                document.getElementById("roomrent").value = Response.data.rent
                var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent }
                setSavedata(ld);
                setLogroom(Response.data.rname)
            })
            .catch((Response) => { "data Not Found" })
    }
    const getroombyid = (e) => {
        console.log(e)
        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)

    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {

            axios.get(`${hostlink}/checkinview`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}${api}/search/${document.getElementById("search").value}`)

                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }
    const SaveData = async (e) => {

        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        const Roomcodecheck = document.getElementById("roomcode").value;
        if (Roomcodecheck == 0) {
            toast.error("Please Select Room")
            return false
        }
        
        
        try {
            if (document.getElementById("pagemode").innerHTML == ' Check-IN [ NEW ]') {

                await axios.post(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = {
                            ...logdetail,
                            newdesc: `Mob No : ${mobno}, Name : ${gname}, Room : ${logroom}, Rent : ${rent}, Ex Bed : ${exbed}, Bed Ch : ${exbedch} , Advance : ${advance}`,
                            operation: "New"
                        }
                        setlogdetail(ld);
                        saveLog(ld)
                        // Update Room Status
                        axios.post(`${hostlink}/room/status/${document.getElementById("roomcode").value}`)
                            .then(() => { })
                            .catch(() => { })
                        setTimeout(() => {
                            setRefreshData(true)
                        }, 1000);
                    })
                    .catch(() => {

                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                console.log(savedata)
                await axios.put(`${hostlink}${api}`, savedata)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = {
                                ...logdetail,
                                newdesc: `Mob No : ${mobno}, Name : ${gname}, Room : ${logroom}, Rent : ${rent}, Ex Bed : ${exbed}, Bed Ch : ${exbedch} , Advance : ${advance}`,
                                operation: "Edit"
                            }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/CheckIn")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        indate: today.toISOString().split('T')[0],
        mobno: "",
        gname: "",
        add1: "",
        add2: "",
        documentno: "",
        documentpath: "",
        email: "",
        gstno: "",
        cname: "",
        rtype: "",
        rcode: "",
        rent: "",
        exbed: "",
        exbedch: "",
        mg: "",
        fg: "",
        cg: "",
        stday: "",
        discount: "",
        advance: "",
        paidby: "",
        remarks: ""
    })

    const { mobno, gname, add1, add2, documentno, documentpath, indate, email, gstno, cname, rcode, rtype, rent, exbed, exbedch, mg, fg, cg, stday, discount, advance, paidby, remarks } = savedata;
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = async (e) => {
        setPagemode(1);

        axios.get(`${hostlink}/room`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
        document.getElementById("pagemode").innerHTML = " Check-IN [ EDIT ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        var roomname;
        x.style.display = "block"
        document.getElementById("inputname").focus();
        document.getElementById("cmdnew").disabled = true
        document.getElementById("roomtype").disabled = true
        document.getElementById("roomcode").disabled = true
        await axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
                axios.get(`${hostlink}/room/${Response.data.rcode}`)
                    .then((Responseroom) => {
                        roomname = (Responseroom.data.rname);
                        axios.get(`${hostlink}${api}/${e}`)
                            .then((Responsenew) => {

                                var ld = {
                                    ...logdetail,
                                    olddesc: `Mob No : ${Responsenew.data.mobno}, Name : ${Responsenew.data.gname}, Room : ${roomname}, Rent : ${Responsenew.data.rent}, Ex Bed : ${Responsenew.data.exbed}, Bed Ch : ${Responsenew.data.exbedch} , Advance : ${Responsenew.data.advance}`,
                                    operation: "Edit"
                                }
                                setlogdetail(ld);
                            })
                            .catch((Responsenew) => { console.log("Data Not find") })
                    })
                    .catch()
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        document.getElementById("pagemode").innerHTML = " Check-IN [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
    }
    const deleteData = async (e, flname) => {

        try {

            await axios.get(`${hostlink}${api}/${e}`)
            .then((Responsenew)=>{

                var ld = {
                    ...logdetail,
                    newdesc: `Mob No : ${Responsenew.data.mobno}, Name : ${Responsenew.data.gname}, Room : ${deletemsg}, Rent : ${Responsenew.data.rent}, Ex Bed : ${Responsenew.data.exbed}, Bed Ch : ${Responsenew.data.exbedch} , Advance : ${Responsenew.data.advance}`,
                    olddesc:"",
                    operation: "Delete"
                }
                setlogdetail(ld);
                saveLog(ld)
                return false
            })
            axios.post(`${hostlink}/room/statusfree/${updatercode}`)
            await axios.delete(`${hostlink}${api}/${e}`)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/Checkin")
            }, 1000);
        } catch (error) { }
    }
    const columns = [
        { name: 'Sr No', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "5%" },
        { name: 'Check-in-Date', sortable: true, selector: row => row.checkInMas.indate, },
        { name: 'Time', sortable: true, selector: row => row.checkInMas.intime, },
        { name: 'Room', sortable: true, selector: row => row.roomName, },
        { name: 'Status', sortable: true, selector: row => row.statusName, },
        { name: 'Guest Name', sortable: true, selector: row => row.checkInMas.gname, },
        { name: 'Guest Mob', sortable: true, selector: row => row.checkInMas.mobno, },
        { name: 'Guest Add', sortable: true, selector: row => row.checkInMas.add1, },
        {
            name: 'Actions', "width": "16%", cell: (row) => (<div>
                <Link to={"/Checkin"} onClick={(e) => { cmdEdit(`${row.checkInMas.id}`) }} className='btn btn-sm btn-outline-dark'>Edit</Link> &nbsp;
                {/* <Link onClick={() => deleteData(row.checkInMas.id, row.checkInMas.flname)} className='btn btn-sm btn-danger'>Delete</Link>&nbsp; */}
                <Link onClick={(e) => { handleOpenModal(row.checkInMas.id, row.roomName, row.checkInMas.rcode) }} className='btn btn-sm btn-danger'>Delete</Link>&nbsp;
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br /> Check-In Room No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid, deletemsg) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>

                <Link className='btn btn-sm btn-info'>Add Room</Link>
            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        document.getElementById("cmdnew").disabled = false
        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        axios.get(`${hostlink}/checkinview`)
            .then((Response) => {


                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }, [refreshData])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-door-open"></i> <span id='pagemode' > Check-IN [ New ]</span></h5>
                <div className='row'>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Mob No</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mobno' value={mobno} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Guest Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gname' value={gname} class="form-control" id="inputname1" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 1</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add1' value={add1} class="form-control" id="inputname11" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 2</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add2' value={add2} class="form-control" id="inputname111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Document No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='documentno' value={documentno} class="form-control" id="inputname1111" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Select Document</label>
                        <input type="file" class="form-control" id="documentpath" />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Email</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="inputname11111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">GST No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="inputname111111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Company Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cname' value={cname} class="form-control" id="inputname1111111" /> <br />
                    </div>
                    {/*- Stay Details -------------------------------------*/}
                    <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Detail</span></h5>

                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Check In Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='indate' value={indate} id="1inputname" /> <br />
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room Type</label>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e); getroombyid(e.target.value); }} name='rtype' value={rtype} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {RoomTYpe.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rtname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room</label>
                        <select id="roomcode" onChange={(e) => { onChangevalue(e) }} onBlur={(e) => { getrent(e.target.value) }} name='rcode' value={rcode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Room.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Rent</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='rent' value={rent} class="form-control" id="roomrent" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Extra Bed</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbed' value={exbed} class="form-control" id="111inputname" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Bed Charge</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbedch' value={exbedch} class="form-control" id="extrabadchrge" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Male</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mg' value={mg} class="form-control" id="11111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Female</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='fg' value={fg} class="form-control" id="111111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Child</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='cg' value={cg} class="form-control" id="2inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Stay Days</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='stday' value={stday} class="form-control" id="22inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Dis %</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='discount' value={discount} class="form-control" id="222inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Advance</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='advance' value={advance} class="form-control" id="2222inputname" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Paid By</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='paidby' value={paidby} class="form-control" id="22222inputname" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Remarks</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='remarks' value={remarks} class="form-control" id="12inputname" /> <br />
                    </div>

                </div>


                <div class="col-md-12 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-door-open"></i> Check-IN Details</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; Create New </button>
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default Checkin
