import axios from 'axios';
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Settings = () => {
    const [data, setData] = useState({
        id: "",
        gst5: "",
        gst12: "",
        gst18: "",
        gst28: ""
    })
    const { gst5, gst12, gst18, gst28 } = data;

    const calcelForm = (e) => {
        window.location.assign("/")
    }

    const savedata = () => {
        axios.get(`${hostlink}$/setting/1`)
            .then((resp) => {
                
                axios.put(`${hostlink}$/setting`, data)
                 .then((resp)=>{
                 })
                 .catch((resp)=>{
                    console.log("Not Save..")
                 })   
            })
            .catch((resp) => {
                axios.post("http://localhost:8080/setting", data)
                    .then((responce) => {
                        console.log("Save Sucess")
                    })
                    .catch(() => {
                        console.log("Data Not Save...")
                    })
            })
            toast.success("Save Sucessfull")
    }

    const onchange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        console.log(data)
    }

    useEffect(() => {
        axios.get("http://localhost:8080/setting/1")
            .then((resp) => {
                setData(resp.data)
            })
            .catch(() => {
                console.log("Data Not Fatch...")
            })
    }, [1])

    return (
        <div>
            <div className='container p-1'>
                <h5 className='font-monospace text-danger'>System Configuration</h5>
                <div className='row'>
                    {/* Column 1 */}
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-6 text-end'><label htmlFor=""> GST 5 % Amt </label></div>
                            <div className='col-md-2 '><input onChange={(e) => { onchange(e) }} name='gst5' value={gst5} type="text" /></div>
                            <div className='col-md-6 text-end'><label html For=""> GST 12 % </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} name='gst12' value={gst12} type="text" /></div>
                            <div className='col-md-6 text-end'><label htmlFor=""> GST 18 % </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} name='gst18' value={gst18} type="text" /></div>
                            <div className='col-md-6 text-end'><label htmlFor=""> GST 28 % </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="text" name='gst28' value={gst28} /></div>
                        </div>
                    </div>
                    {/* Column 2 */}
                    <div className='col-md-4'>
                        <div className='row'>

                        </div>
                    </div>

                    {/* Column 3 */}
                    <div className='col-md-4'>
                        <div className='row'>

                        </div>
                    </div>

                    {/* Save Button */}
                    <div className='col-md-6'></div>
                    <div className='col-md-6 text-end'>
                        <br />
                        <button class="btn btn-primary" onClick={() => { savedata() }}>Save</button>  &nbsp;
                        <button class="btn btn-outline-dark" onClick={() => { calcelForm() }}>Cancel</button>
                    </div>

                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default Settings