import React from 'react'
import css from "./app.module.css"
import Navbar from './Component/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ItemUnit_View from './Component/ItemUnit_View'
import Home from './Component/Home'
import ItemGroupMaster from './Component/ItemGroupMaster'
import KitchenMaster from './Component/KitchenMaster'
import DishTypeMaster from './Component/DishTypeMaster'
import ItemCompanyMaster from './Component/ItemCompanyMaster'
import DishHeadMaster from './Component/DishHeadMaster'
import FloorMaster from './Component/FloorMaster'
import RoomMaster from './Component/RoomMaster'
import TableMaster from './Component/TableMaster'
import ItemMaster from './Component/ItemMaster'
import Logbook from './Component/Logbook'
import RoomTypeMaster from './Component/RoomTypeMaster'
import CheckIn from './Component/CheckIn'
import CheckOut from './Component/CheckOut'
import SaleBill from './Component/SaleBill'
import MessageBox from './Component/MessageBox'
import SaleBillTrash from './Component/SaleBillTrash'
import SaleBillCancel from './Component/SaleBillCancel'
import Test from './Component/Test'
import Reservation from './Component/Reservation'
import Settings from './Component/Settings'


const App = () => {
  return (
    <div id={css.app}>
          <Navbar/>
          <BrowserRouter>
            <Routes>
              <Route path='/' Component={Home}></Route>
              <Route path='/ItemUnit_View' Component={ItemUnit_View}></Route>
              <Route path='/ItemGroupMaster' Component={ItemGroupMaster}></Route>
              <Route path='/KitchenMaster' Component={KitchenMaster}></Route>
              <Route path='/DishTypeMaster' Component={DishTypeMaster}/>
              <Route path='/ItemCompanyMaster' Component={ItemCompanyMaster}/>
              <Route path='/DishHeadMaster' Component={DishHeadMaster}/>
              <Route path='/FloorMaster' Component={FloorMaster}/>
              <Route path='/RoomMaster' Component={RoomMaster}/>
              <Route path='/TableMaster' Component={TableMaster}/>
              <Route path='/ItemMaster' Component={ItemMaster}/>
              <Route path='/Logbook' Component={Logbook}/>
              <Route path='/RoomTypeMaster' Component={RoomTypeMaster}/>
              <Route path='/CheckIn' Component={CheckIn}/>
              <Route path='/CheckOut' Component={CheckOut}/>
              <Route path='/SaleBill' Component={SaleBill}/>
              <Route path='/MessageBox' Component={MessageBox}/>
              <Route path='/SaleBillTrash' Component={SaleBillTrash}/>
              <Route path='/SaleBillCancel' Component={SaleBillCancel}/>
              <Route path='/Test' Component={Test}/>
              <Route path='/Reservation' element={<Reservation/>}/>
              <Route path='/Settings' element={<Settings/>}/>
            </Routes>
          </BrowserRouter>     
          
    </div>
  )
}

export default App
